.PlayerComponent {
    flex: 1;
    display: flex;
    flex-direction: column;
    user-select: none;
    /* border: 5px solid red; */
    touch-action: none;
    /* font-family: sans-serif; */
    /* overflow: hidden; */
}

.GraphicsElement {
    /* border: 0px solid red; */
    position: relative;
    flex: 1;
    overflow: auto;
    color: black;
    /* background-image: url("./graphics/logo.svg"); */
    background-size: 20rem, auto, contain;
    background-repeat: no-repeat;
    background-position: center;
    touch-action: none;
}

.VideoElementContainer {
    background: black;
    color: white;
    touch-action: none;
    display: flex;
    flex: 1;
    overflow: auto;
    justify-content: center;
    align-items: center;
}

.VideoElement {
    max-width: 100%;
    max-height: 100%;
}

.PagesElement {
    /* background: pink; */
    /* border: 3px solid red; */
    overflow: auto;
}

.PagesOverlayElement {
    /* border: lime 10px solid; */
    position: absolute;
    pointer-events: none;
}

.PlayheadOverlayElement {
    position: absolute;
    pointer-events: none;
    border: purple 0px solid;
}

.PlayheadElement {
    position: absolute;
    width: 5px;
    background-color: rgba(255, 166, 0, .7);
    top: 0;
    left: 0;
    height: 5rem;
    visibility: hidden;
}

.DocumentPage {
    background: white;
    border-radius: .3rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.11);
    overflow: hidden;
}

.DocumentPage>div>svg {
    width: 100%;
    height: 100%;
}

.BtnTurn {
    position: absolute;
    top: 40%;
    width: 8rem;
    cursor: pointer;
    z-index: 50;
}

.BtnTurnRight {
    right: 0;
    transform: rotate(180deg);
}

.BtnTurn>svg {
    fill: rgba(0, 0, 0, .05);
    /* filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 1)); */
}

.BtnTurn>svg:hover {
    fill: rgba(0, 0, 0, .1);
}

.PagesStatus {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000022;
    z-index: 100;
    display: none;
    pointer-events: none;
}

.PagesStatusDisplay {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: var(--header-background);
    color: white;
    border-radius: .6rem;
    font-size: 150%;
    padding: 1rem 1.4rem;
    transform: translateX(-50%) translateY(-50%);
}

.DevToolbar {
    padding: .5rem;
    background-color: gray;
    gap: .3rem;
    display: flex;
    align-items: center;
}

button.selected {
    background: var(--header-background);
    border: 0px;
    border-radius: .4rem;
    padding: .3rem .7rem;
    color: white;
}

.GridItem {
    cursor: pointer;
    display: flex;
    gap: .4rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 0px solid #0002;
    /* pointer-events: none;  Set programatically by player depending on mobile/desktop */
}

@media (hover: hover) and (pointer: fine) {
    .GridItem:hover {
        background: rgba(0, 0, 255, .05)
    }
}

.GridSign {
    color: black;
    font-size: 90%;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    padding: .1rem .3rem .2rem .3rem;
    border-radius: .4rem;
    color: white;
    transform: translateY(-100%);
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
}

.SignRight {
    align-self: flex-end;
    margin-right: 0;
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}

.Pass0 {
    background: #1E90FF;
    /* transform: translateY(-100%); */
}

.Pass1 {
    background: #499b5d;
    /* transform: translateY(0%); */
}

.Pass2 {
    background: rgb(141, 112, 69);
    /* transform: translateY(100%); */
}

.Pass3 {
    background: rgb(146, 76, 140);
    /* transform: translateY(200%); */
}

.Pass4 {
    background: rgb(209, 89, 41);
}

.Pass5 {
    background: #829b49;
}

.Pass6 {
    background: rgb(95, 76, 146);
}

.Pass7 {
    background: rgb(146, 76, 97);
}


.GridSyncFlag {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    background: gray;
    transform: translateX(-50%);
}

.GridSyncFlag::after {
    /* content: '1'; */
    content: attr(data-text);
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    top: 100%;
    background: black;
    position: absolute;
    border-radius: 50%;
    transform: translateX(-40%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 90%;
    font-weight: bold;
}

.SyncItem0, .SyncItem0::after {
    background: dodgerblue;
}

.SyncItem1, .SyncItem1::after {
    background: tomato;
}

.SyncItem2, .SyncItem2::after {
    background: rgb(15, 172, 15);
}