@import url('https://fonts.googleapis.com/css?family=Poppins');

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-family: 'Poppins', sans-serif;
  height: 100%;
}

select,
input {
  font-family: 'Poppins', sans-serif;
}

body {
  font-family: 'Poppins', sans-serif;
  height: 100%;
  color: var(--text-opposite);
  /* background: #efefef; */
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  /* border: 4px solid lime; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  padding-left: 1rem;
}

li {
  text-decoration: none;
}
