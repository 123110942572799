:root {
  --text: #eadfd8;
  --text_8: rgba(234, 223, 216, 0.8);
  --text_5: rgba(234, 223, 216, 0.5);

  --text-opposite: #1f2437;

  --active-link: #42a4ff;

  --primary: #2b65e3;
  --primary-darker: #204fb3;
  --primary-almost-invisible: rgba(43, 101, 227, 0.2);

  --background: #191e2e;
  --body-background: #191e2e;
  --background-offset-up: #181c2b;
  --background-offset-up_5: #181c2b80;

  --toggle-active-background: #22283d;
  --toggle-active-text: #eadfd8;

  --header-background: #161a28;
  --card-background: #22283d;
  --icon-background: #545c79;
  --icon-background-offset: #2c3249;
  --icon-background-offset_5: #2c324980;
  --modal-background: #1b2031;
  --modal-background-offsetUp: #1d2234;

  --empty-list-item-background: #161a28;
  --empty-list-item-row: #191e2e;

  --inputIcon: #878da733;

  --error: #f78962;
  --deny: #ff6c5f;
  --premium: #f5c958;
  --purchase: #a4d8c3;
  --purchaseText: #1f2437;

  --success: #49a149;
  --discount: #57c966;

  --player-button: #1f2a49;
  --player-button-transparent: #1f2a4998;
  --player-background: #efefef;

  --font-family: Poppins;
}
