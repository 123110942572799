header {
  background: whitesmoke;
  border-bottom: 1px solid lightgray;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

h2,
h3 {
  margin: 0;
}

span {
  font-size: 90%;
}

main {
  /* padding: .3rem 0; */
  flex: 1;
  /* display: flex; */
  /* justify-content: stretch; */
  /* flex-direction: column; */
  align-items: stretch;
  /* border: 1px solid purple; */
}

footer {
  background: whitesmoke;
  border-top: 1px solid lightgray;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 0.5rem;
}

footer .arrangementName {
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#Profile {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  height: inherit;
}

#Profile>img {
  object-fit: cover;
  height: 2.2rem;
  border-radius: 50%;
}

.react-tabs {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.react-tabs__tab-panel {
  padding: 0 0.5rem;
  flex: 1;
  flex-direction: column;
  display: flex;
}

.react-tabs__tab-panel--selected {
  display: flex;
}

.react-tabs__tab-list {
  margin-bottom: 0;
}

/* .Forms {

display: flex;
flex-direction: column;
gap: 2rem;
}
*/
/* .Forms>form {
  padding: 2rem;
  border: 3px solid #eee;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  min-width: 40rem;
}

.Forms>form>button {
  padding: .5rem;
  font-size: 105%;
}

.Forms>form>input {
  padding: .5rem;
  border-radius: .3rem;
  border: 2px solid gray;
  text-align: center;
  font-size: 105%;
} */

.text.secondary {
  color: #888;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgb(252, 252, 252);
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  padding: .5rem 1rem 1rem 1rem;
}

.card form {
  padding: 0;
}

.Centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
  flex: 1;
}

form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: stretch;
  align-items: stretch;
  max-width: 30rem;
  text-align: left;
  flex: 1;
  padding: 2rem 0;
}

form label {
  display: flex;
  flex-direction: column;
}

form label span {
  display: block;
  color: gray;
}

form label input {
  justify-self: flex-end;
  border: gray 1px solid;
  border-radius: 0.2rem;
}

input,
select {
  padding: 0.5rem;
}

input,
select {
  font-size: 1rem;
  border: gray 1px solid;
  border-radius: 0.2rem;
}

button {
  padding: 0.3rem;
}

.Dropzone {
  margin: 1rem 0;
  padding: 2rem;
  background: #eee;
  width: 100%;
  border-radius: 0.5rem;
  border: dotted 3px black;
  cursor: pointer;
}

/* .FlexColumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 40rem;
} */

.FlexCenter {
  display: flex;
  justify-content: center;
}

.Form span {
  margin: 5px 0;
}

details>div {
  padding-left: 1rem;
}

.RecordingFileItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 2px solid gray;
  border-radius: 0.4rem;
  padding: 0.5rem;
}

.RecordingsList {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

select {
  font-size: 90%;
  padding: 0.2rem;
}

.RecordingFileItem input {
  font-size: 90%;
  padding: 0.2rem;
}

.RecordingFileItem input[type='number'] {
  width: 5rem;
}

h2 {
  padding: 0.5rem 0 0.5rem;
}

label {
  display: flex;
  flex-direction: column;
}

.InterestedParty {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 2px solid gray;
  border-radius: 0.4rem;
  padding: 0.5rem;
}

.InterestedParty label {
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.InterestedParty p {
  font-weight: bold;
}

.InterestedParty span {
  font-size: 80%;
  color: gray;
}

span.MetadataSingle {
  display: block;
  font-size: inherit;
  color: black;
}

.Existing {
  border-color: dodgerblue;
}

.NonExisting {
  border-color: orange;
}

.CreateNew {
  border: dotted gray 3px;
}

.Important {
  border-color: tomato;
}

.Columns {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1rem;
  width: 98%;
}

.ItemList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0 0.5rem;
}

summary {
  cursor: pointer;
}

summary a,
span a {
  font-size: 80%;
  border: 1px solid gray;
  padding: 0.2rem 0.2rem 0rem;
  border-radius: 0.2rem;
  text-decoration: none;
}

.Info {
  padding: 1.3rem;
  /* max-width: 60rem; */
  background: rgb(223, 229, 255);
  border-radius: 0.3rem;
  margin: 1rem 0;
}

.Info p {
  margin-bottom: 0.3rem;
}

.Info p:last-child {
  margin-bottom: 0;
}

.Flex {
  display: flex;
  gap: 0.5rem;
}

.FlexRow {
  flex-direction: row;
}

.FlexColumn {
  flex-direction: column;
}

.JustifyCenter {
  justify-content: center;
}

.JustifyStretch {
  justify-content: stretch;
}

.JustifyBetween {
  justify-content: space-between;
}

.JustifyStart {
  justify-content: flex-start;
}

.JustifyEnd {
  justify-content: flex-end;
}

.AlignCenter {
  align-items: center;
}

.AlignStretch {
  align-items: stretch;
}

.AlignStart {
  align-items: flex-start;
}

.AlignEnd {
  align-items: flex-end;
}

.DevBorderRed {
  border: 2px solid red;
}

.DevBorderPurple {
  border: 2px solid purple;
}

.Flex1 {
  flex: 1 1;
}

.Flex0 {
  flex: 0;
}

.Gap0 {
  gap: 0;
}

.Gap05 {
  gap: 0.5rem;
}

.Gap02 {
  gap: 0.2rem;
}

.Pad1 {
  padding: 1rem;
}

.Pad05 {
  padding: 0.5rem;
}

.Pad0-05 {
  padding: 0 0.5rem;
}

.BGLightGray {
  background: #efefef;
}

.BGDarkGray {
  background: #555;
  color: white;
}

.BGddd {
  background: #ddd;
}

.BGred {
  background: red;
}

.BGtomato {
  background: tomato;
}

.BGlime {
  background: lime;
}

.BGWhite {
  background-color: white;
}

.BD1Gray {
  border: 1px solid #ccc;
}

.BDRed {
  border: 1px solid;
  border-color: red;
}

.BD3 {
  border: 3px;
}

.BD_TB_1Gray {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.H2 {
  height: 2rem;
}

.H100 {
  height: 100%;
}

.W2Rem {
  width: 2rem;
}

.W3Rem {
  width: 3rem;
}

.W4Rem {
  width: 4rem;
}

.FlexGap0 {
  display: flex;
  gap: 0;
}

.Relative {
  position: relative;
}

.chip {
  background: black;
  border-radius: 1rem;
  padding: 0.2rem 0.5rem;
  color: white;
  width: fit-content;
  height: fit-content;
}

.ArrStatus {
  background: rgb(177, 255, 177);
  border-radius: 1rem;
  padding: 0.2rem 0.5rem;
  color: black;
  width: fit-content;
  height: fit-content;
  font-weight: normal !important;
}

.ArrStatus.Draft {
  background: rgb(255, 220, 155);
}

.ArrStatus.PreviouslyPublished {
  background: rgb(155, 207, 255);
}

.ArrStatus.ReadyForPublish {
  background: rgb(228, 255, 155);
}

.NoWrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.MeterWrapper {
  width: 3.5rem;
}

button.small {
  padding: 0 0.3rem 0.1rem 0.3rem;
}

button.active {
  padding: 0.1rem 0.31rem 0.2rem 0.31rem;
  background-color: dodgerblue;
  color: white;
  border: none;
  border-radius: 0.3rem;
}

button.selected {
  padding: 0.1rem 0.31rem 0.2rem 0.31rem;
  background-color: limegreen;
  color: white;
  border: none;
  border-radius: 0.3rem;
}

input.small {
  padding: 0.1rem 0.3rem;
  font-size: 90%;
  width: 6rem;
}

input.medium {
  padding: 0.1rem 0.3rem;
  font-size: 90%;
  width: 10rem;
}

.RoundedBorder {
  display: flex;
  flex-direction: row;
  border: 2px solid gray;
  border-radius: 0.4rem;
  padding: 0.5rem;
}

.Grid3Cols {
  display: grid;
  grid-template-columns: 1fr 1fr fr;
}

.Grid3ColsX {
  display: grid;
  grid-template-columns: 1fr 1fr 10rem;
}

.VideoLink {
  display: block;
  padding: 1rem 0;
}

.Button {
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  padding: 10px;
  transition: opacity 0.2s ease;
  cursor: pointer;
  font-size: 14px;
  width: fit-content;
}

.Button:hover {
  opacity: 0.8;
}

.Button:active {
  opacity: 0.5;
}

.DisabledButton {
  pointer-events: none;
  opacity: 0.4;
}

.modal-overlay {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box {
  display: block;
  background: white;
  width: 95%;
  height: 95%;
  padding: .5rem;
  border-radius: .3rem;
  position: relative;
}

.MenuBurger {
  padding: .7rem .7rem;
  border: none;
  border-radius: 10px;
}

.HoverVisible {
  opacity: .3;
}

.HoverVisible:hover {
  opacity: 1;
  border-radius: .2rem;
}