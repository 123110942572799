/* Webapp
.Player2Sync {
    background: var(--color-header-background);
    position: absolute;
    bottom: 7rem;    
    width: 100%;
}
*/

/*Testbench*/
.Player2Sync {
    background: var(--color-header-background);
    width: 100%;
}




.Player2SyncToolbar {
    display: flex;
    /* justify-content: flex-end; */
    gap: 2rem;
}

.SyncToolbar {
    display: flex;
    padding: 1rem;
    gap: .3rem;
    justify-content: space-between;
}

.SyncToolbar>div {
    color: black;
    display: flex;
    gap: .3rem;
}

.BarJumpsEditor {
    padding: .5rem;
}

.TimeSyncEditor {
    padding: .5rem;
    /* background: lime; */
}

.BarSyncWrapper {
    margin: .3rem;
    height: 2rem;
}

.BarSyncItem {
    border-radius: .2rem;
    border: .1rem solid blacK;
    font-weight: bold;
    font-size: 120%;
    padding: .1rem .5rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background: #1E90FF;
}

.BarSyncItem0 {
    background: #1E90FF;
}

.BarSyncItem1 {
    background: #499b5d;
}

.BarSyncItem2 {
    background: rgb(141, 112, 69);
}

.BarSyncItem3 {
    background: rgb(146, 76, 140);
}

.BarSyncItem4 {
    background: rgb(95, 76, 146);
}

.BarSyncItem5 {
    background: #499b5d;
}

.BarSyncItem6 {
    background: rgb(141, 112, 69);
}

.BarJumpsToolbar {
    display: flex;
    gap: 1rem;
    height: 3rem;
    align-items: center;
    padding: .4rem;
    margin: 1rem 0;
}

.BarJumpFunctions {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 2rem 0;
}

.BarJumpFunctionButtons {
    display: flex;
    gap: 1rem;
}

.BarEditForm {
    display: flex;
    gap: 1rem;
    align-items: center;
}


.BarSyncInput {
    width: 5rem;
    color: black;
    border-radius: .5rem;
    padding: .2rem;
}

.BarSyncInput:focus {
    background: rgb(165, 255, 184);
}

.button {
    position: relative;
    display: inline-flex;
    font-size: 1rem;
    justify-content: center;
    border: 0;
    padding: .4rem .6rem;
    border-radius: .3rem;
    border: .1rem black;
    background-color: blue;
    color: white;
    cursor: pointer;
    transition: opacity .2s ease;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border: 0.1rem black solid;
    background-color: rgb(35, 35, 61);
}

button.selected {
    background: dodgerblue;
    color: white;
    border-radius: .5rem;
}

button.active {
    background: lime;
    color: white;
    border-radius: .5rem;
}

/* button:disabled {
    color: #aaa;
    border: 1px color #aaa;
} */


.small {
    padding: 0rem 1rem 1rem;
    height: 2.2rem;
}

.alert {
    background-color: tomato;
}

.h2 {
    font-size: 1.6rem;
}

.TimeSyncEditor {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.TimeSyncTimelineButtons {
    background: #555;
    height: 1.5rem;
    border-radius: .7rem;
    position: relative;
    margin: .1rem .5rem;
}

.TimeSyncItemButton {
    background: lime;
    width: 1rem;
    position: absolute;
    top: .15rem;
    bottom: 0;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    position: absolute;
    padding: 0;
    font-size: 80%;
    color: white;
}

.SyncItem0 {
    background-color: dodgerblue;
}

.SyncItem1 {
    background-color: tomato;
}

.SyncItem2 {
    background-color: #499b5d;
}

.TimeSyncToolbar {
    display: flex;
    gap: 1rem;
    height: 3rem;
    align-items: center;
    justify-content: space-between;
}

.HoverDisplay {
    position: absolute;
    width: 3px;
    background: white;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    height: 1rem;
}

.ItemDisplay {
    padding: .3rem;
    border-radius: .2rem;
    margin: 0;
    background: red;
    position: absolute;
    width: 2rem;
    transform: translateX(-50%);
    color: white;
    font-size: 130%;
    display: flex;
    justify-content: center;
    align-items: center;
}