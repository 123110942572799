.horizontal-slider {
    width: 100%;
    max-width: 100%;
    height: 1.4rem;
    /* border: 1px solid grey; */
    padding: 0 1rem;
}


.example-track {
    top: .4rem;
    height: .4rem;
    background: red;
    border-radius: .3rem;

}

.example-track-0 {
    background: dodgerblue;
}

.example-track-1 {
    background: #ccc;
}

.example-track-2 {
    background: white;
}

.example-track-3 {
    background: #ccc;
}

.example-thumb {
    top: 0rem;
    width: 1.2rem;
    height: 1.2rem;
    text-align: center;
    background-color: dodgerblue;
    color: white;
    cursor: grab;
    box-sizing: border-box;
    border-radius: 50%;
    padding: .3rem;
}

.example-thumb {
    /* border: 8px solid purple; */
}

.example-thumb-0 {
    background: dodgerblue;
    /* border-radius: 50% 0 0 50%; */
}

.example-thumb-1 {
    background: dodgerblue;
    /* border-radius: 0 50% 0 0; */
}

.example-thumb-2 {

    background: white;
}