.BarSyncWrapper {
    margin: .3rem;
    height: 1.8rem;
}

.BarSyncItem {
    border-radius: .5rem;
    border: 3px solid #ddd;
    /* font-weight: bold; */
    /* font-size: 120%; */
    padding: .1rem .3rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background: #1E90FF;
}

.BarSyncItem0 {
    background: #1E90FF;
}

.BarSyncItem1 {
    background: #499b5d;
}

.BarSyncItem2 {
    background: rgb(141, 112, 69);
}

.BarSyncItem3 {
    background: rgb(146, 76, 140);
}

.BarSyncItem4 {
    background: rgb(95, 76, 146);
}

.BarSyncItem5 {
    background: #499b5d;
}

.BarSyncItem6 {
    background: rgb(141, 112, 69);
}

.BarJumpsToolbar {
    display: flex;
    gap: 1rem;
    height: 3rem;
    align-items: center;
    padding: .4rem;
    margin: 1rem 0;
}

.BarJumpFunctions {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 2rem 0;
}

.BarJumpFunctionButtons {
    display: flex;
    gap: 1rem;
}

.BarEditForm {
    display: flex;
    gap: 1rem;
    align-items: center;
}


.BarSyncInput {
    width: 4rem;
    color: black;
    border-radius: .2rem;
    border: 1px solid #aaa;
    padding: .1rem 0 .1rem .3rem;
    font-size: 90%;
}

.BarSyncInput:focus {
    background: rgb(165, 255, 184);
}

button.alert {
    background-color: tomato;
    color: white;
    border: none;
    border-radius: .3rem;
}

button.nudgeLeft {
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
    border: 1px solid gray;
}

button.nudge {
    border: 1px solid gray;
}

button.nudgeRight {
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    border: 1px solid gray;
}